document.addEventListener('DOMContentLoaded', () => {
	/* Update the date in the header */
	const dateSpan = document.querySelector('.date');
	if (dateSpan) {
	const day = new Date().toLocaleDateString(undefined, { weekday: 'long' });
	dateSpan.textContent = day;
	}
	
	/* -------------------------------
	Background Change on Scroll
	------------------------------- */
	const bgSections = document.querySelectorAll('[data-background]');
	const body = document.body;
	const bgObserver = new IntersectionObserver((entries) => {
	entries.forEach(entry => {
	if (entry.isIntersecting) {
		// Remove any existing background-* classes
		body.classList.forEach(cls => {
		if (cls.startsWith('background-')) {
			body.classList.remove(cls);
		}
		});
		// Add the new background class based on data attribute
		const bgValue = entry.target.dataset.background;
		body.classList.add(`background-${bgValue}`);
	}
	});
	}, { threshold: 0.5 });
	bgSections.forEach(section => bgObserver.observe(section));
	
	/* -------------------------------
	Flipping Text Animation
	------------------------------- */
	const animatingElements = [];
	
	function flipText(element, finalText, characterRange, totalDuration) {
	const data = {
		element: element,
		finalText: finalText.split(''),
		startTime: performance.now(),
		totalDuration: totalDuration, // e.g., 1000 ms for 1 second
		length: finalText.length,
		characterRange: characterRange
	};
	animatingElements.push(data);
	if (animatingElements.length === 1) {
		requestAnimationFrame(animateFlip);
	}
	}
	
	function animateFlip() {
	const now = performance.now();
	for (let i = animatingElements.length - 1; i >= 0; i--) {
		const data = animatingElements[i];
		const elapsed = now - data.startTime;
		let fraction = elapsed / data.totalDuration;
		if (fraction > 1) fraction = 1;
		// Determine how many characters have "settled"
		const finalCount = Math.floor(fraction * data.length);
		const displayedText = data.finalText.map((char, idx) => {
		return idx < finalCount
			? char
			: data.characterRange[Math.floor(Math.random() * data.characterRange.length)];
		}).join('');
		data.element.textContent = displayedText;
		if (fraction === 1) {
		animatingElements.splice(i, 1);
		}
	}
	if (animatingElements.length > 0) {
		requestAnimationFrame(animateFlip);
	}
	}
	
	function isElementInViewport(el) {
	const rect = el.getBoundingClientRect();
	const elementHeight = rect.height;
	// Calculate the visible height of the element.
	const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
	// Trigger only if at least 50% of the element is visible.
	return (visibleHeight / elementHeight) >= 0.5;
	}
	
	function onScrollFlipText() {
	const elements = document.querySelectorAll('.flipping-text');
	elements.forEach(el => {
		if (isElementInViewport(el) && !el.dataset.animated) {
		// Use data-text if provided; otherwise, use the element's current text
		const finalText = el.getAttribute('data-text') || el.textContent;
		const characterRange = '!#$%&*()¡–ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const totalDuration = 1250; // Animation lasts 1 second
		flipText(el, finalText, characterRange, totalDuration);
		el.dataset.animated = true;
		}
	});
	}
	
	window.addEventListener('scroll', onScrollFlipText);
	onScrollFlipText(); // Initial check in case some are already in view
	
	/* -------------------------------
	Image Change Effect (.change)
	------------------------------- */
	const changeElements = document.querySelectorAll('.change');
	changeElements.forEach(element => {
	const images = element.querySelectorAll('img');
	if (images.length < 2) return;
	let currentIndex = 0;
	images.forEach((img, i) => {
	img.style.display = (i === currentIndex) ? 'block' : 'none';
	});
	setInterval(() => {
	currentIndex = (currentIndex + 1) % images.length;
	images.forEach((img, i) => {
		img.style.display = (i === currentIndex) ? 'block' : 'none';
	});
	}, 2000);
	});
	
	/* -------------------------------
	Scroll-Driven 3D .move Effect
	------------------------------- */
	const moveFigures = document.querySelectorAll('figure.move');
	const identity = new DOMMatrix();
	moveFigures.forEach(figure => {
	const randomX = (Math.random() - 0.5) * 200;
	const randomY = (Math.random() - 0.5) * 400;
	const randomRotateX = (Math.random() - 0.5) * 60;
	const randomRotateY = (Math.random() - 0.5) * 60;
	const randomRotateZ = (Math.random() - 0.5) * 60;
	let M_rand = new DOMMatrix();
	M_rand = M_rand.translate(randomX, randomY)
				.rotateAxisAngle(1, 0, 0, randomRotateX)
				.rotateAxisAngle(0, 1, 0, randomRotateY)
				.rotateAxisAngle(0, 0, 1, randomRotateZ);
	// Store the matrix as an array for later interpolation
	figure.dataset.randomMatrix = JSON.stringify([
	M_rand.m11, M_rand.m12, M_rand.m13, M_rand.m14,
	M_rand.m21, M_rand.m22, M_rand.m23, M_rand.m24,
	M_rand.m31, M_rand.m32, M_rand.m33, M_rand.m34,
	M_rand.m41, M_rand.m42, M_rand.m43, M_rand.m44,
	]);
	figure.style.transformStyle = 'preserve-3d';
	});
	
	// Adjust threshold if needed; here, elements fully "settle" when they reach 62.5% of the viewport height
	const threshold = window.innerHeight * 0.625;
	function lerp(a, b, t) {
	return a + (b - a) * t;
	}
	
	function updateFigures() {
	const windowHeight = window.innerHeight;
	moveFigures.forEach(figure => {
	const rect = figure.getBoundingClientRect();
	let progress = rect.top < threshold
		? 1
		: 1 - ((rect.top - threshold) / (windowHeight - threshold));
	progress = Math.max(0, Math.min(progress, 1));
	
	const M_rand_array = JSON.parse(figure.dataset.randomMatrix);
	const M_rand = new DOMMatrix(M_rand_array);
	const M_interp = new DOMMatrix([
		lerp(M_rand.m11, identity.m11, progress),
		lerp(M_rand.m12, identity.m12, progress),
		lerp(M_rand.m13, identity.m13, progress),
		lerp(M_rand.m14, identity.m14, progress),
		lerp(M_rand.m21, identity.m21, progress),
		lerp(M_rand.m22, identity.m22, progress),
		lerp(M_rand.m23, identity.m23, progress),
		lerp(M_rand.m24, identity.m24, progress),
		lerp(M_rand.m31, identity.m31, progress),
		lerp(M_rand.m32, identity.m32, progress),
		lerp(M_rand.m33, identity.m33, progress),
		lerp(M_rand.m34, identity.m34, progress),
		lerp(M_rand.m41, identity.m41, progress),
		lerp(M_rand.m42, identity.m42, progress),
		lerp(M_rand.m43, identity.m43, progress),
		lerp(M_rand.m44, identity.m44, progress)
	]);
	// Interpolate scale from 80% to 100%
	const scaleFactor = lerp(0.8, 1, progress);
	const scaleMatrix = new DOMMatrix().scale(scaleFactor);
	const finalMatrix = M_interp.multiply(scaleMatrix);
	figure.style.transform = finalMatrix.toString();
	});
	requestAnimationFrame(updateFigures);
	}
	updateFigures();
  
	const scrambleSpan = document.querySelector('.scramble');
	const words = [
	"a vibrant mosaic",
	"an intricate patchwork",
	"a diverse array",
	"a vivid collage",
	"an elaborate medley",
	"a multifaceted blend",
	"a dynamic collection",
	"a colorful assortment",
	"an interwoven fabric",
	"a complex weave"
	];
	let currentIndex = 0;
	
	function typeWord(word, charIndex = 0) {
	if (charIndex <= word.length) {
		// 10% chance to make a typo
		if (charIndex < word.length && Math.random() < 0.1) {
		const wrongChar = String.fromCharCode(97 + Math.floor(Math.random() * 26));
		scrambleSpan.textContent = word.substring(0, charIndex) + wrongChar;
		// Let the wrong letter be visible a bit longer
		setTimeout(() => {
			// Remove the wrong letter
			scrambleSpan.textContent = word.substring(0, charIndex);
			// Pause a moment before typing the correct letter
			setTimeout(() => {
			scrambleSpan.textContent = word.substring(0, charIndex + 1);
			setTimeout(() => {
				typeWord(word, charIndex + 1);
			}, 100 + Math.random() * 100);
			}, 300);
		}, 400);
		} else {
		scrambleSpan.textContent = word.substring(0, charIndex + 1);
		setTimeout(() => {
			typeWord(word, charIndex + 1);
		}, 100 + Math.random() * 100);
		}
	} else {
		// Word complete; wait then erase it
		setTimeout(() => eraseWord(() => {
		currentIndex = (currentIndex + 1) % words.length;
		typeWord(words[currentIndex]);
		}), 2000);
	}
	}
	
	function eraseWord(callback) {
	const currentText = scrambleSpan.textContent;
	if (currentText.length > 0) {
		scrambleSpan.textContent = currentText.slice(0, -1);
		setTimeout(() => eraseWord(callback), 50);
	} else {
		callback();
	}
	}
	
	scrambleSpan.textContent = "";
	typeWord(words[currentIndex]);
});

document.addEventListener('DOMContentLoaded', () => {
  // --- SLIDESHOW SETUP ---
  // Collect both images and videos from the page in DOM order.
  const slideshowElements = Array.from(document.querySelectorAll('img:not(.skip), video'));
  let currentIndex = 0;
  let slideshowActive = false;

  // Create the overlay div and a container for the slideshow content.
  const slideshowOverlay = document.createElement('div');
  slideshowOverlay.id = 'slideshow-overlay';
  Object.assign(slideshowOverlay.style, {
	position: 'fixed',
	top: '0',
	left: '0',
	width: '100vw',
	height: '100vh',
	padding: '2.5em',
	backgroundColor: 'rgba(0,0,0,1)',
	display: 'none',
	justifyContent: 'center',
	alignItems: 'center',
	zIndex: '10000',
	cursor: 'pointer',
	boxSizing: 'border-box',
  });

  // Use explicit dimensions for the content container.
  const slideshowContent = document.createElement('div');
  slideshowContent.id = 'slideshow-content';
  Object.assign(slideshowContent.style, {
	width: '100%',
	height: '100%',
	position: 'relative',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
  });
  slideshowOverlay.appendChild(slideshowContent);

  // Create a caption container that will be positioned at the bottom center.
  const slideshowCaption = document.createElement('div');
  slideshowCaption.id = 'slideshow-caption';
  slideshowOverlay.appendChild(slideshowCaption);

  document.body.appendChild(slideshowOverlay);

  // --- 3D EFFECT ANIMATION FUNCTION ---
  function animateElement3D(el) {
	// Remove any previous transition.
	el.style.transition = 'none';
	// Generate a random transform effect.
	const randomX = (Math.random() - 0.5) * 100; // adjust range as needed
	const randomY = (Math.random() - 0.5) * 100;
	const randomRotateX = (Math.random() - 0.5) * 25;
	const randomRotateY = (Math.random() - 0.5) * 25;
	const randomRotateZ = (Math.random() - 0.5) * 25;
	const startScale = 0.825; // starting scale (approx. 82.5%)
	el.style.transform = `translate(${randomX}px, ${randomY}px) rotateX(${randomRotateX}deg) rotateY(${randomRotateY}deg) rotateZ(${randomRotateZ}deg) scale(${startScale})`;
	// Force reflow so the browser registers the starting transform.
	void el.offsetWidth;
	// Set transition and animate to identity (i.e. no transform).
	el.style.transition = 'transform 505ms ease-out';
	el.style.transform = 'none';
  }

  // --- SLIDESHOW CONTROLS ---
  function showElement() {
	// Clear previous content.
	slideshowContent.innerHTML = '';
	slideshowCaption.textContent = '';
	
	// Clone the current element.
	const currentEl = slideshowElements[currentIndex];
	const clone = currentEl.cloneNode(true);
	
	// If it's a video, enable autoplay.
	if (clone.tagName.toLowerCase() === 'video') {
	  clone.setAttribute('autoplay', '');
	}
	
	// Style the clone to fit within the container.
	Object.assign(clone.style, {
	  maxWidth: '100%',
	  maxHeight: '100%',
	  width: 'auto',
	  height: 'auto',
	  objectFit: 'contain',
	  display: 'block'
	});
	
	slideshowContent.appendChild(clone);
	
	// Apply the 3D swoop effect.
	animateElement3D(clone);
	
	// Retrieve caption text from the related figure.
	const figureEl = currentEl.closest('figure');
	if (figureEl) {
	  const figcaptionEl = figureEl.querySelector('figcaption');
	  if (figcaptionEl) {
		// Use data-text if present; otherwise, use the element's text.
		const captionText = figcaptionEl.getAttribute('data-description') || figcaptionEl.textContent;
		slideshowCaption.textContent = captionText;
	  }
	}
  }

  function nextElement() {
	currentIndex = (currentIndex + 1) % slideshowElements.length;
	showElement();
  }

  function prevElement() {
	currentIndex = (currentIndex - 1 + slideshowElements.length) % slideshowElements.length;
	showElement();
  }

  function exitSlideshow() {
	slideshowOverlay.style.display = 'none';
	slideshowActive = false;
  }

  // --- EVENT LISTENERS ---
  document.addEventListener('keydown', (e) => {
	// Activate slideshow when "S" is pressed.
	if (!slideshowActive && e.key.toLowerCase() === 's') {
	  slideshowActive = true;
	  currentIndex = 0; // Start at the first element.
	  showElement();
	  slideshowOverlay.style.display = 'flex';
	  e.preventDefault();
	} else if (slideshowActive) {
	  // Navigate with arrow keys or exit with Escape.
	  if (e.key === 'ArrowRight') {
		nextElement();
		e.preventDefault();
	  } else if (e.key === 'ArrowLeft') {
		prevElement();
		e.preventDefault();
	  } else if (e.key === 'Escape') {
		exitSlideshow();
		e.preventDefault();
	  }
	}
  });

  // Clicking on the content advances forward.
  slideshowContent.addEventListener('click', (e) => {
	e.stopPropagation();
	nextElement();
  });

  // Clicking outside the content (on the overlay) exits slideshow.
  slideshowOverlay.addEventListener('click', () => {
	exitSlideshow();
  });
});